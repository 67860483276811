<template>
  <div v-show="visible" class="tools-wrapper">
    <!-- <div class="im-consult" @click="handleImConsult">
      <div class="avatar-wrapper">
        <div class="ani-circle"></div>
        <div class="avatar">
          <img
            src="https://mktv-in-cdn.mockuai.com/16240060133929089.png"
            alt=""
          />
        </div>
      </div>
      <div class="text">客服咨询</div>
    </div> -->
    <div class="options">
      <div
        class="option-item"
        @mouseenter="handleChatConsultEnter"
        @mouseleave="handleChatConsultLeave"
      >
        <img
          src="https://mktv-in-cdn.mockuai.com/16277312260445258.png"
          alt=""
          class="icon"
        />
        <div class="text">主播合作</div>
      </div>
      <div class="option-item"
        @mouseenter="handleChatConsultEnter"
        @mouseleave="handleChatConsultLeave"
      >
        <img
          src="https://mktv-in-cdn.mockuai.com/16279583499496387.png"
          alt=""
          class="icon"
        />
        <div class="text">商家合作</div>
      </div>
      <div
        class="option-item"
        @mouseenter="handleChatConsultEnter"
        @mouseleave="handleChatConsultLeave"
      >
        <img
          src="https://mktv-in-cdn.mockuai.com/16602000396811260.png"
          alt=""
          class="icon"
        />
        <div class="text">课程培训</div>
      </div>
    </div>
    <div class="backtop" @click="backtop">
      <img
        src="https://mktv-in-cdn.mockuai.com/16279584414377098.png"
        alt=""
        class="icon"
      />
      <div class="text">返回顶部</div>
    </div>
    <consult-phone ref="consultPhone"></consult-phone>
    <consult-enterprise-we-chat ref="consultChat"></consult-enterprise-we-chat>
    <img v-show="anchorCooperationShow" class="opt-img opt1" src="https://mktv-in-cdn.mockuai.com/16792954136854209.jpg?x-oss-process=image/resize,w_300/quality,Q_90" alt="">
    <img v-show="supplierCooperationShow" class="opt-img opt2" src="https://mktv-in-cdn.mockuai.com/16792962083648959.jpg?x-oss-process=image/resize,w_300/quality,Q_90" alt="">
  </div>
</template>

<script>
import ConsultPhone from "@/components/ConsultPhone.vue";
import ConsultEnterpriseWeChat from "@/components/ConsultEnterpriseWeChat.vue";
import { loadJS, isClient } from "@/utils";
export default {
  components: {
    ConsultPhone,
    ConsultEnterpriseWeChat,
  },
  // props: {
  //   hidden: {
  //     type: Boolean,
  //     default: false,
  //   }
  // },
  data() {
    return {
      visible: false,
      anchorCooperationShow: false,
      supplierCooperationShow: false,
    };
  },
  created() {
    isClient(() => {
      loadJS(
        "https://qiyukf.com/script/97a8bb5512338e5d873d15a1148509c1.js",
        () => {
          // this.sidebar.contact = true
          // this.sidebar.ready = true
        }
      );
    });
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleImConsult() {
      if (window._czc) {
        window._czc.push(["_trackEvent", "侧边栏客服咨询", "点击"]);
      }
      const url = window.ysf("url") + "&templateId=6613603";
      window.open(url, "", "height=630, width=840");
    },
    handleChatConsultEnter() {
      this.$refs.consultChat.show();
    },
    handleChatConsultLeave() {
      this.$refs.consultChat.close();
    },
    handlePhoneConsultEnter() {
      this.$refs.consultPhone.show();
    },
    handlePhoneConsultLeave() {
      this.$refs.consultPhone.close();
    },
    handleEnter(img) {
      this[img] = true;
    },
    handleLeave(img) {
      this[img] = false;
    },
    jump(url) {
      const obj = {
        "/collect-anchor": "主播开店",
        "/collect-store": "商家入驻",
      };
      if (window._czc) {
        window._czc.push(["_trackEvent", `侧边栏${obj[url]}`, "点击", url]);
      }
      window.open(url);
    },
    backtop() {
      console.log("backtop");
      // window.scrollTo({ top:0, behavior:"smooth"})
      this.$emit("backtop");
      if (window._czc) {
        window._czc.push(["_trackEvent", "返回顶部按钮", "点击"]);
      }
    },
  },
};
</script>


<style lang="less" scoped>
@keyframes inner {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outer {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.opt-img {
  position: fixed;
  right: 112px;
  width: 300px;
}
.opt1 {
  top: 20%;
}

.opt2 {
  top: 30%;
}

.tools-wrapper {
  position: fixed;
  z-index: 40;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .im-consult {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .avatar-wrapper {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      height: 88px;
      border-radius: 50%;
      .ani-circle {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 88px;
        height: 88px;
        border: 1px solid rgba(255, 102, 0, 0.35);
        border-radius: 50%;
        opacity: 0;
        animation: 1.2s ease-out 1.2s infinite outer;
      }
      .avatar {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        border: 2px solid #ff6600;
        // background-image: url(https://mktv-in-cdn.mockuai.com/16240060133929089.png);
        // background-repeat: no-repeat;
        // background-size: cover;
        img {
          width: 100%;
          height: 100%;
          animation: 1.2s ease-out 1.2s infinite inner;
        }
      }
    }
    .text {
      box-sizing: border-box;
      position: relative;
      top: -8px;
      width: 72px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid #ffffff;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      &:hover {
        color: #ff6600;
      }
    }
  }
  .options {
    box-sizing: border-box;
    // margin-top: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #ffffff;
    .option-item {
      width: 72px;
      height: 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        .text {
          color: #ff6600;
        }
      }
      &:nth-child(1) {
        .icon {
          width: 32px;
          height: 32px;
        }
      }
      &:nth-child(2) {
        .icon {
          width: 32px;
          height: 33px;
        }
      }
      &:nth-child(3) {
        .icon {
          width: 22px;
          height: 23px;
        }
      }
      &:nth-child(4) {
        .icon {
          width: 32px;
          height: 32px;
        }
      }
      .icon {
        object-fit: cover;
        margin-bottom: 2px;
      }
      .text {
        line-height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .backtop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    margin-top: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover {
      .text {
        color: #ff6600;
      }
    }
    .icon {
      width: 32px;
      height: 32px;
      margin-bottom: 1px;
    }
    .text {
      line-height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
    }
  }
}
</style>
